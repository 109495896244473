//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dialog } from "vant";
export default {
  name: "storeDailyReportDetail",
  data() {
   return {
      active:0,
      storeName:'',
      storeId:'',
      title:'',
      isShowDatePicker:false,
      goodsList:[],//竞品列表
      userList:[],//店员列表
      id:'',
      currentDate:new Date(),
      minDate:new Date(2021,0,0),
      currentMonth:'',
      datetime:'',
      chooseDate:new Date(),
      monthTargetSale:"",//月销售额目标
      sales:'',//销售额
      isCache:1,
      posAmount:0,//付款总金额
      posAmountSupply:0,//补录POS金额
      ordersNum:"",//今日单数
      goodsNum:"",//今日件数
      empathyGoodsNum:"",//今日通情杆销售件数
      highOrdersNum:"",//高单数（4件以上含）
      remark:"",//销售分析
      storeDaySale:{},//门店日报详情
      storeInfos:{},//门店累计销售
      storeDateSaleStoreCompetingBrandList:[],
      storeDateSalePersonnelList:[],
    };
  },
  created() {
    console.log('$route.params',this.$route.params);
    this.currentMonth = this.$moment(new Date().getTime()).format('MM')
    this.datetime = this.$moment(new Date().getTime()).format("yyyy-MM-DD")
    this.minDate = this.$moment(this.$route.params.minDate).add(1,'days').toDate()
    let storeId = this.$route.params.storeCode
    if(!storeId){
      this.$toast({ message: "门店数据丢失，请重新选择门店", duration: 2000 });
      this.$router.replace({name:"StoreInfo"});
    }else{
      this.storeName = this.$route.params.storeName
      this.storeId = storeId
      if(this.$route.params.id){
        this.id = this.$route.params.id
        window.document.title = '编辑日报';
        this.title = '编辑日报';
        this.getStoreDaySaleDetails()
      }else{
        this.getTargetSales()
        window.document.title = '新增日报';
        this.title = '新增日报';
        this.getStoreSalesSum()
        this.getSapStorePosOrder()
      }
    }
  },
  computed: {
     //月销售额 = 当前月销售额 + 日销售额
    totalSumSales(){
      return this.id ? Number(this.storeInfos.MD_SumSales).toFixed(0) : (Number(this.storeInfos.MD_SumSales) +  Number(this.sales)).toFixed(0)
    },
    //年销售额 = 当前年销售额 + 日销售额
    yearSumSales(){
      return this.id ? Number(this.storeInfos.MD_SumSales).toFixed(0) : (Number(this.storeInfos.YD_SumSales) +  Number(this.sales)).toFixed(0)
    },
    //月达成率  = 累计销售 /月份目标
    monthCompleteRate(){
      if(!this.monthTargetSale) return '0%'
      return (this.totalSumSales * 100 / (this.monthTargetSale )).toFixed(1) + '%'
    },
    //月同比 
    monthCompareAgo(){
      if(this.storeInfos.MA_SumSales == 0) return '0%'
      return ((this.totalSumSales - this.storeInfos.MA_SumSales)* 100/this.storeInfos.MA_SumSales).toFixed(1)  + '%'
    },
    //未入POS
    posAmountNotIn(){
      return Math.max((this.sales - this.posAmount).toFixed(1),0)
    },
    //连带率 = 件数/单数
    goodsOrderRate(){
      if(this.goodsNum == 0 || this.ordersNum == 0) return 0
      return (this.goodsNum / this.ordersNum).toFixed(2)
    },
  },
  methods: {
    submit(isCache) {
      console.log("onSubmit");
      let that = this;
      if(!this.sales){
          this.$toast({message: '请输入当日销售额', duration: 3000});
          return false;
      }
      if(this.ordersNum === ''){
          this.$toast({message: '请输入今日单数', duration: 3000});
          return false;
      }
      if(this.goodsNum  === ''){
          this.$toast({message: '请输入今日件数', duration: 3000});
          return false;
      }
      if(this.highOrdersNum  === ''){
          this.$toast({message: '请输入高单数（4件以上含）', duration: 3000});
          return false;
      }
      if(this.empathyGoodsNum  === ''){
          this.$toast({message: '请输入今日通情杆销售件数', duration: 3000});
          return false;
      }
       let postData = {
        "datetime": this.datetime,
        "sales": this.sales,
        "storeId":  this.storeId,
        "empathyGoodsNum": this.empathyGoodsNum,
        "goodsNum": this.goodsNum,
        "highOrdersNum": this.highOrdersNum,
        "ordersNum": this.ordersNum,
        "remark": this.remark,
        "isCache":isCache,
        "storeName":  this.storeName,
        posAmount:this.posAmount || 0,
        posAmountNotIn:this.posAmountNotIn || 0,
        posAmountSupply:this.posAmountSupply || 0,

        totalSumSales:this.totalSumSales || 0,
        monthCompleteRate:this.monthCompleteRate || '0%',
        monthCompareSale:this.storeInfos.MA_SumSales || 0,
        monthCompareAgo:this.monthCompareAgo || 0,
        yearSumSales:this.yearSumSales || 0,
        
        goodsOrderRate:this.goodsOrderRate || 0,
        monthTargetSale:this.monthTargetSale
      }
      if(this.id){
        postData.id = this.id
      }
      postData.requestStoreCompetingBrandData = [];
      this.goodsList.map(v =>{
          postData.requestStoreCompetingBrandData.push({
            "sales": v.sales || 0,
            "storeCompetingBrandId": v.storeCompetingBrand.id
          })
      })
      postData.requestPersonnelData = [];
      this.userList.map(v =>{
          postData.requestPersonnelData.push({
            "sales": v.sales || 0,
            "hrId": v.hrid
          })
      })

      console.log(postData);
      Dialog.confirm({
        title: '确认' + (isCache == 1 ? '暂存' : '提交'),
        message: (isCache == 1 ? '暂存后' : '提交后不可编辑，') + '不可新增' + this.datetime + '前的日报',
      })
      .then(() => {
        that.axios.post("/consumer/store/daysale/saveStoreDaySaleData",postData)
        .then((res) => {
            console.log(res);
            if(res.data.code === 0){
              that.$toast({ message: "保存成功", duration: 2000 });
              setTimeout(()=>{
                that.goBack()
              },2000)
            }else{
              that.$toast({ message: res.data.msg, duration: 2000 });
            }
          });
      })
      .catch(() => {
        // on cancel
      });
      
    },
    goBack() {
      this.$router.go(-1);
    },
    //获取日报详情
    getStoreDaySaleDetails() {
      const that = this;
      this.axios
        .get("/consumer/store/daysale/getStoreDaySaleDetails?id=" + that.id)
        .then((res) => {
          console.log("getStoreDaySaleDetails",res);
          if (res.data && res.data.data) {
            Object.assign(this.$data,res.data.data.storeDaySale)
            that.currentMonth = that.$moment(res.data.data.storeDaySale.month,'M').format('MM')
            that.datetime = res.data.data.storeDaySale.datetime
            that.chooseDate = that.$moment(res.data.data.storeDaySale.datetime,'yyyy-MM-DD')
            that.getTargetSales()
            that.getStoreSalesSum()
            that.getSapStorePosOrder()
            that.storeDateSaleStoreCompetingBrandList = res.data.data.storeDateSaleStoreCompetingBrandList
            that.storeDateSalePersonnelList = res.data.data.storeDateSalePersonnelList
            
          }
        });
    },
    //获取POS门店小票列表
    getSapStorePosOrder() {
      const that = this;
      let postData = {
        "queryMap": {
          "q_kunnr": this.storeId,
          "q_startDate": this.datetime,
          "q_endDate": this.datetime,
        },
        "queryMapObject": {}
      }
      this.axios
        .post("/consumer/store/daysale/getSapStorePosOrder",postData)
        .then((res) => {
          console.log("getSapStorePosOrder",res);
          if (res.data && res.data.data) {
            let posAmount = 0
            res.data.data.map(v =>{
               posAmount += parseInt(v.ttlval) * 100
            })
            that.posAmount = posAmount / 100
          }
        });
    },
    //获取门店累计销售
    getStoreSalesSum() {
      const that = this;
      let postData = {
        "queryMap": {
          "q_storeId": this.storeId,
          "q_datetime": this.datetime,
        },
        "queryMapObject": {}
      }
      this.axios
        .post("/consumer/store/daysale/getStoreSalesSum",postData)
        .then((res) => {
          console.log("getStoreSalesSum",res);
          if (res.data && res.data.data) {
            that.storeInfos  = res.data.data;
            that.getStoreStoreCompetingBrandsByStoreId()
            that.getSysUserListByStore()
          }
        });
    },
    //获取门店对应的竞品列表
    getStoreStoreCompetingBrandsByStoreId() {
      const that = this;
      this.axios
        .get("/consumer/store/daysale/getStoreStoreCompetingBrandsByStoreId?storeId=" + that.storeId)
        .then((res) => {
          console.log("getStoreStoreCompetingBrandsByStoreId",res.data.data);
          if (res.data && res.data.data && res.data.data.length > 0) {
             res.data.data.map(item =>{
               that.storeInfos.storeDaySaleStoreCompetingBrandMap.map(v =>{
                  if(v.storecompetingbrand_id == item.storeCompetingBrandId){
                    item.sumSales = v.sumSales 
                  }
                  that.storeDateSaleStoreCompetingBrandList.map(goods =>{
                    if(goods.storeCompetingBrandId == item.storeCompetingBrandId){
                      item.sales = goods.sales
                    }
                  })
              })
             })
             that.goodsList = res.data.data;
          }
        });
    },
    //获取门店中的人员
    getSysUserListByStore() {
      const that = this;
      this.axios
        .get("/consumer/store/daysale/getSysUserListByStore?storeId=" + that.storeId)
        .then((res) => {
          console.log("getSysUserListByStore",res.data.data);
          if (res.data && res.data.data && res.data.data.length > 0) {
            res.data.data.map(item =>{
              that.storeInfos.storeDaySalePersonnelMap.map(v =>{
                if(v.hr_id == item.hrid){
                  item.sumSales = v.sumSales 
                }
              })
              that.storeDateSalePersonnelList.map(user =>{
                if(user.hrId == item.hrid){
                  item.sales = user.sales
                }
              })
             })
            that.userList = res.data.data;
          }
        });
    },
    getTargetSales() {
      const that = this;
      var pdata = [{
                'PARAMETER': 'Z0CALDAY_MVIM_001',
                'SIGN': 'I',
                'OPTION': 'BT',
                'LOW': this.$moment(this.chooseDate).startOf('month').format('yyyy-MM-DD'),
                'HIGH': this.$moment(this.chooseDate).endOf('month').format('yyyy-MM-DD'),
                
            },  {
                'PARAMETER': 'Z0CUSTOMER_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                "LOW_FLAG":"1",
                'LOW': that.storeId,
                'HIGH': ''
            }]
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
            "/bw/BwApi/BWP_ChildSaleList",
          pdata
        )
        .then((res) => {
          console.log("BWP_ChildSaleList", res.data);
          if (res && res.data && res.data.data && res.data.data.length > 0) {
            // MD_AmountIndex 当期月指标(万)
            that.monthTargetSale = res.data.data[0].MD_AmountIndex * 10000
          }
        });
    },
    showDatePicker(){
      if(this.id && this.isCache == 0) {
        this.$toast({message: '提交后不可修改日期', duration: 3000});
        return
      }
      this.isShowDatePicker = true;
    },
    confirmDatePicker(val) {
      console.log(val);
      this.datetime =  this.$moment(val).format("yyyy-MM-DD"),
      this.currentMonth = this.$moment(new Date().getTime()).format("MM"),
      this.getSapStorePosOrder()
      this.getStoreSalesSum()
      this.getTargetSales()
      this.isShowDatePicker = false;
    },
    closeDatePicker(){
      this.isShowDatePicker = false;
    },
  }
 
};
